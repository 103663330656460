@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

/* font-family: "Nunito", sans-serif; */
/* font-family: "Poppins", sans-serif; */
.admin-dashboard-main-top {
  height: 8vh;
  display: grid;
  justify-content: left;
  align-items: center;
  margin: 2vw;
}
.admin-dashboard-main-top button {
  font-family: "Nunito", sans-serif;
  display: grid;
  grid-template-columns: 2vw 1fr;
  height: 5vh;
  width: 8vw;
  border: none;
  border-radius: 6px;
}

.admin-dashboard-main-top div {
  display: grid;
  place-items: center;
  height: 100%;
  font-weight: 700;
  font-size: 1vw;
  color: white;
}

.admin-dashboard-main-mid {
  margin: 2vw;
  background-color: white;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 6px;
}

.admin-dashboard-main-top-last {
  height: 8vh;
  display: grid;
  justify-content: left;
  align-items: center;
}

/* .admin-dashboard-main-top-last button {
  font-family: "Nunito", sans-serif;
  display: grid;
  grid-template-columns: 2vw 1fr;
  height: 5vh;
  width: 6vw;
  border: none;
  border-radius: 6px;
} */

.admin-dashboard-main-top-last div {
  display: grid;
  place-items: center;
  height: 100%;
  font-weight: 700;
  font-size: 1vw;
  color: #062a59;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.admin-dashboard-main-mid-item {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.2vw;
}

.admin-dashboard-main-mid-item input::placeholder {
  color: #d7d7d7;
  font-weight: 500;
  font-size: 0.8vw;
  font-family: "Poppins", sans-serif;
}

.admin-dashboard-main-mid-item input {
  background: #ffffff;
  border: 2px solid #dfdfdf;
  border-radius: 5px;
  width: 18vw;
  margin: 0.5vw 1vw 1vw 1vw;
  color: #000;
  font-weight: 500;
  font-size: 0.8vw;
  font-family: "Poppins", sans-serif;
  padding: 0.5vh 0.5vw;
}

.filter-section {
  display: flex;
  align-items: center;
  gap: 20px;
}

.admin-dashboard-main-mid-item select {
  background: #ffffff;
  border: 2px solid #dfdfdf;
  border-radius: 5px;
  width: 13vw;
  height: 4vh;
  margin: 0.5vw 1vw 1vw 1vw;
  color: #000;
  font-weight: 500;
  font-size: 0.8vw;
  font-family: "Poppins", sans-serif;
  padding: 0 0.5vw;
}

.admin-dashboard-main-mid-item-txt {
  margin: 1vw 0 0 1vw;
}

.admin-dashboard-main-mid-item-last {
  margin: 1vw;
}

.admin-dashboard-main-bottom-item-left-btm button {
  font-weight: 700;
  font-size: 0.8vw;
  color: white;
  font-family: "Nunito", sans-serif;
  border: none;
  border-radius: 6px;
  height: 4vh;
  width: 6vw;
  margin: 1vh 0;
}

.admin-dashboard-main-bottom-item-right-cnt {
  width: 5vw;
  height: 9vw;
  margin-top: -1.5vw;
  margin-bottom: -1.5vw;
  border-radius: 0 0.5vw 0.5vw 0;
  display: grid;
  place-items: center;
  color: white;
  font-weight: 700;
  font-size: 1.2vw;
  font-family: "Nunito", sans-serif;
}

.admin-dashboard-main-bottom-item {
  display: grid;
  grid-template-columns: 1fr 4vw;
  width: 22vw;
  background-color: white;
  padding: 1vw;
  border-radius: 6px;
}
.admin-dashboard-main-bottom-item-right {
  display: grid;
  place-items: center;
}

.admin-dashboard-main-bottom {
  margin: 2vw 2vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 2vw;
  column-gap: 2vw;
}

.admin-dashboard-main-bottom-item-left-top {
  font-weight: 600;
  font-size: 1.2vw;
  font-family: "Poppins", sans-serif;
}

.admin-dashboard-main-bottom-item-left-mid {
  font-weight: 500;
  font-size: 1vw;
  color: #121212;
  margin: 1vh 0;
  font-family: "Poppins", sans-serif;

}
