@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

/* font-family: "Nunito", sans-serif; */
/* font-family: "Poppins", sans-serif; */

.admin-overdue-top {
  height: 6vw;
  display: grid;
  justify-content: left;
  align-items: center;
  margin: 0 2vw;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.7vw;
  color: #00495f;
}

.admin-overdue-mid {
  background-color: white;
  margin: 0 2vw;
  border-radius: 6px;
}

.admin-overdue-mid-left-input-right {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.admin-overdue-mid {
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 600;
  font-size: 1.2vw;
  padding: 15px;
}

.admin-overdue-mid input {
  font-weight: 500;
  font-size: 0.8vw;
  /* color: #d7d7d7; */
  width: 16vw;
  height: 3vw;
  background: #ffffff;
  border: 2px solid #1e3758;
  outline: none;
  border-radius: 4px;
  padding: 1vw 1vw;
}

.rowInput {
  font-weight: 500;
  font-size: 1vw;
  background: #ffffff;
  border: 2px solid black;
  border-radius: 4px;
}

.admin-overdue-mid select {
  width: 20vw;
  border: 2px solid#1E3758;
  border-radius: 6px;
  outline: #0475b5;
  padding: 15px;
}

.admin-overdue-mid select option {
  padding: 30px;
  color: #0475b5;
}

.admin-dashboard-main-mid-item-last-filter {
  display: grid;
  justify-content: left;
  align-items: center;
}

/* .admin-dashboard-main-top-last-filter button {
  font-family: "Nunito", sans-serif;
  display: grid;
  grid-template-columns: 2vw 1fr;
  height: 4vh;
  width: 6vw;
  border: none;
  border-radius: 6px;
} */

/* .admin-dashboard-main-top-filter button {
  font-family: "Nunito", sans-serif;
  grid-template-columns: 2vw 1fr;
  height: 5vh;
  width: 9vw;
  border: none;
  border-radius: 6px;
} */

.admin-dashboard-main-top-last-filter div {
  display: grid;
  place-items: center;
  height: 100%;
  font-weight: 700;
  font-size: 1vw;
  color: white;
}

.admin-dashboard-main-top-filter div {
  display: grid;
  place-items: center;
  height: 100%;
  font-weight: 700;
  font-size: 1vw;
  color: white;
}

.admin-overdue-mid-left {
  /* margin: 1vw 1vw; */
}

.admin-overdue-mid-left-txt {
  margin: 0 0 1vw 0;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #2c343e;
}

.admin-overdue-bottom {
  margin: 2vw;
  background-color: white;
  border-radius: 6px;
  padding: 1px;
}

.admin-overdue-bottom-table-cnt {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  padding: 1vw 2vw;
}

.admin-overdue-bottom-table-cnt-ext {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  padding: 1vw 1vw;
}

.admin-overdue-bottom-table-cnt-certi {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  padding: 1vw 1vw;
}

.admin-overdue-bottom-table-cnt-grp {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 1vw 1vw;
}

.admin-overdue-bottom-table-head {
  font-weight: 600;
  font-size: 1.1vw;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 0 1vw 0;
  text-align: center;
  color: #2c343e;
  font-family: "Poppins", sans-serif;
  word-break: break-word;
  padding: 5px;
}

.admin-overdue-bottom-pagination {
  display: grid;
  place-items: center;
  margin: 2vw;
}

.admin-overdue-bottom-pagination-cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}

.admin-overdue-bottom-pagination-cnt-item {
  margin: 0 0.8vw;
  display: grid;
  place-items: center;
}

.admin-overdue-bottom-pagination-cnt-item-btn {
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 1.1vw;
  font-family: "Poppins", sans-serif;
  color: #717579;
}

.admin-overdue-bottom-table-td {
  font-weight: 400;
  font-size: 1vw;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 0 1vw 0;
  text-align: center;
  color: #717579;
  font-family: "Poppins", sans-serif;
  word-break: break-word;
}

.admin-row {
  font-weight: 400;
  font-size: 1vw;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 0 1vw 0;
  display: inline;
  text-align: center;
  color: black;
  font-family: "Poppins", sans-serif;
  word-break: break-word;
}

.admin-remind-notify {
  position: absolute;
  z-index: 4;
  background-color: #e5e5e5c3;
  display: grid;
  place-items: center;
  height: 64vh;
  width: 100%;
  margin-top: -1vw;
}

.admin-remind-notify-main {
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  width: 50vw;
  height: auto;
  padding: 2vw;
}

.admin-dashboard-main-mid-item-last-filter-notify {
  /* height: 8vh; */
  display: grid;
  justify-content: right;
  align-items: center;
  margin-left: 1.6vw;
}

.admin-dashboard-main-top-last-filter-notify {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.admin-dashboard-main-top-last-filter-notify button {
  font-family: "Nunito", sans-serif;
  display: grid;
  place-items: center;
  width: 8vw;
  border: none;
  border-radius: 6px;
  margin: 0 1vw;
}

.admin-dashboard-main-top-last-filter-notify div {
  display: grid;
  place-items: center;
  height: 100%;
  font-weight: 700;
  font-size: 1vw;
  color: white;
}

.admin-remind-notify-main-txt {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.1vw;
  color: #2c343e;
  margin: 1vw;
  display: flex;
  justify-content: space-between;
  width: 30vw;
}

.admin-remind-notify-main-head {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.1vw;
  color: #2c343e;
  text-align: center;
}

.admin-remind-notify-main-txt input {
  width: 15vw;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  background-color: rgb(231, 227, 227);
  height: 2vw;
}

.admin-overdue-mid-grp {
  display: grid;
  align-items: center;
  font-weight: 500;
  font-size: 1vw;
  background-color: white;
  margin: 0 2vw;
  border-radius: 6px;
}

.admin-overdue-bottom-table-cnt-grped {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  padding: 1vw 2vw;
}

.admin-overdue-bottom-table-cnt-grpeden {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  padding: 1vw 2vw;
  gap: 15px;
}

.admin-overdue-grop-cnt {
  margin: 2vw;
  background-color: white;
  border-radius: 6px;
}

#admin-course-main-top-id-grp {
  font-weight: 500;
  font-size: 18px;
  color: #2c343e;
  font-family: "Poppins", sans-serif;
  margin: 1vw;
}

.admin-course-main-top-id-grp-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-weight: 500;
  font-size: 18px;
  margin: 0.5vw;
  border-bottom: 2px solid #d7d7d7;
  place-items: center;
}

.admin-course-main-top-id-grp-btm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  font-weight: 500;
  font-size: 16px;
  margin: 0.5vw;
  border-bottom: 2px solid #d7d7d7;
  place-items: center;
}

.admin-course-main-top-id-grp-row button {
  border: none;
  background-color: #f2f2f2;
  padding: 1vh;
  width: 6vw;
  border-radius: 4px;
}
