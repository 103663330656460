@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

.courselist {
  background-color: #e5e5e5;
}

.courselist-main {
  display: grid;
  grid-template-columns: 19vw 1fr;
}

.courselist-main-cnt-top {
  background-color: #e5e5e5;
  height: 5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2vw;
}

.courselist-main-cnt-top-txt {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4vw;
  color: #00495f;
}

/* .courselist-main-cnt-top button {
  width: 20vw;
  height: 5vh;
  font-weight: 700;
  font-size: 1.1vw;
  font-family: "Poppins", sans-serif;
  color: white;
  border: none;
  border-radius: 6px;
  background: linear-gradient(90deg, #007848 0%, #004577 105.46%);
} */

.courselist-main-cnt-bottom {
  background-color: white;
  padding: 0.1vh;
  margin: 0 2vw;
  /* height: 80vh; */
  border-radius: 3px;
}

.courselist-main-cnt-bottom-heading {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 1vw 2vw;
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  font-size: 1.3vw;
  text-align: left;
  padding-left: 4vw;
}

.courselist-main-cnt-back {
  background-color: white;
  margin: 1vw 2vw;
  height: 18vh;
  display: grid;
  place-items: center;
  border-radius: 3px;
}

/* .courselist-main-cnt-back-btn button {
  font-weight: 600;
  font-size: 1.2vw;
  font-family: "Nunito", sans-serif;
  width: 6vw;
  height: 4vh;
  border: none;
  border-radius: 4px;
  color: white;
} */

.admin-overdue-bottom-table-cnt-c {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 1vw 2vw;
}

#c-div {
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #717579;
  display: grid;
  place-items: center;
}

.admin-course-main-top-inputlable-tarea {
  width: 20vw;
  height: 4vh;
  border: 2px solid #d7d7d7;
  border-radius: 6px;
  height: 8vh;
}

.course-head {
  font-family: "Poppins", sans-serif;
  color: #2c343e;
  font-weight: 600;
  font-size: 22px;
  margin: 1vw 2vw;
}

.admin-training-main {
  display: flex;
  flex-direction: row;
}

.admin-upload-material {
  font-family: "Poppins";
  display: flex;
  margin-left: 200px;
  flex-direction: column;
  width: 100%;
}

.admin-drop-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 168px;
  background: #f8f8ff;
  border: 1px dashed rgba(56, 78, 183, 0.3);
  border-radius: 4px;
}

.fileList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
}
