.outer-details-container {
  /* border: 2px solid gray; */
  margin: 54px;
}

.bigger-container-header {
  color: #2c343e;
  font-weight: 500;
  font-family: "Poppins";
}

.user-data-bigger-container {
  font-family: "Poppins";
  margin-top: 16px;
  margin-bottom: 8px;
}
.user-data-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 14px;
}

.user-data-label {
  color: #1e3758;
  font-weight: 500;
  font-family: "Poppins";
}

.user-data-input {
  border: 2px solid #1e3758;
  border-radius: 5px;
  padding: 10px 15px;
  outline: none;
  background-color: transparent;
  margin-top: 10px;
}

.username-container {
  display: flex;
  /* justify-content: space-between; */
  padding: 0px 14px;
}

.preview-container {
  /* text-right pr-2 my-4 */
  text-align: right;
  margin: 25px 0px;
  padding-right: 26px;
}
.create-new-box-pdf {
  text-align: right;
  margin-top: 15px;
  margin-right: 30px;
}
.srollbar_div {
  overflow-y: scroll;
  background-color: #e5e5e5;
}
