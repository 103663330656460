@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
button {
  cursor: pointer;
}
body {
  overflow-x: hidden;
}

.custom-scrollbar::-webkit-scrollbar {
  overflow-x: hidden;
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d9d9d9;
}

.primary-btn {
  background: linear-gradient(94.87deg, #062a59 0%, #c2512c 99.3%);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #fff;
  border: none;
  outline: none;
  padding: 5px 30px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.primary-btn-input {
  background: linear-gradient(94.87deg, #062a59 0%, #c2512c 99.3%);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #fff;
  border: none;
  outline: none;
  padding: 5px 15px;
  color: #fff;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.secondary-btn {
  background: transparent;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #062a59;
  outline: none;
  padding: 5px 30px;
  color: #062a59;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.clear-btn {
  background: none;
  border: none;
  outline: none;
  padding: 5px 20px;
  color: #062a59;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.action-btn {
  background-color: transparent;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #af4d30;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 20px;
  border-radius: 5px;
}
.view-btn {
  background-color: #d55628;
  color: #fff;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
}

.image-box {
  width: 230px;
  height: 180px;
  border: 2px dashed #1e3758;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.image-box-btn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px dashed #1c1b1f;
  display: flex;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.image-box-text {
  color: #1e3758;
  font-family: "Poppins", sans-serif;
}
