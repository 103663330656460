.logo{
  width: 270px;
  height: 90px;
  position: relative;
  top: -25px;
  background: #fff;
}

::placeholder {
  color: green;
  opacity: 20%;
  font-weight: 700;
  font-size: 16px;
}


/* .loginpage {
  font-family: 'Nunito', sans-serif !important;
} */

/*

.loginpage-main {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.loginpage-main-left {
  height: 100vh;
  display: grid;
  place-items: center;
}

.loginpage-main-left-cnt {
  background-color: #e6fcf5;
  width: 36vw;
  height: 40vw;
  border-radius: 10px;
  display: grid;
  place-items: center;
}
.loginpage-main-left-cnt-items {
  display: grid;
  grid-template-rows: 4vw 2vw 4vw 3vw 4vw 3vw 4vw 4vw 3vw;
}

.loginpage-main-left-cnt-items-text-1 {
  font-size: 48px;
  font-weight: 700;
  color: #007848;
  text-align: left;
  display: grid;
  align-items: center;
  justify-content: left;
}

.loginpage-main-left-cnt-items-text-2 {
  font-size: 20px;
  font-weight: 400;
  color: #007848;
  display: grid;
  align-items: center;
  justify-content: left;
}

.loginpage-main-left-cnt-items-text-3 {
  font-size: 16px;
  font-weight: 700;
  color: #007848;
  display: grid;
  align-items: center;
  justify-content: left;
  margin-bottom: -1vh;
}
.loginpage-main-left-cnt-items-input input {
  width: 26vw;
  height: 4vh;
  border-radius: 6px;
  border: 1px solid #007848;
  background-color: #e6fcf5;
  padding: 0.8vh 1vw;
}

.loginpage-main-left-cnt-items-input input::placeholder {
  color: rgba(0, 120, 72, 0.2);
  font-weight: 700;
}

.loginpage-main-left-cnt-items-text-4 {
  font-size: 16px;
  font-weight: 700;
  color: #007848;
  display: grid;
  align-items: center;
  justify-content: left;
  margin-bottom: -1vh;
}

.loginpage-main-left-cnt-items-text-5 {
  color: rgba(0, 120, 72, 1);
  font-weight: 700;
  font-size: 16px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.loginpage-main-left-cnt-items-login-btn button {
  background-color: rgba(0, 120, 72, 1);
  width: 28vw;
  height: 5.5vh;
  border-radius: 6px;
  border: none;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
}

.loginpage-main-left-cnt-items-text-6 {
  color: rgba(0, 120, 72, 1);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.loginpage-main-right {
  display: grid;
  place-items: center;
}

.loginpage-main-right-cnt {
  
}
.loginpage-main-right-cnt img {
  height: 100%;
} */
