.main_contaner_intra {
  display: flex;

  margin-top: 50px;
}

.firstContanerIndiv {
  background-color: #f1eeee;

  margin: auto;
  padding: 0px;

  width: 320px;
  height: 262px;
}
.innerDivInFirstDiv {
  border: 1px solid green;
  width: 290px;
  height: 190px;

  /* White */

  background: #ffffff;
  box-shadow: 0px 0px 25px -10px rgba(213, 86, 40, 0.15);
  border-radius: 24px;

  /* Inside auto layout */
  margin: auto;
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 2;
}
.innerDivInFirstDivSecond {
  width: 290px;
  height: 200px;

  /* White */
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 25px -10px rgba(213, 86, 40, 0.15);
  border-radius: 24px;
  margin: 75px auto 0px auto;
  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 0;
}

#imgid {
  margin: auto;
  margin-top: -70px;
  border-radius: 7px;
  height: 169px;
  width: 240px;
}

.contentBoxIndiv {
  font-size: 16px;
}
.buttenBoxIndiv {
  display: flex;
  justify-content: space-around;
}
.buttenDignIndiv {
  width: 95px;
  height: 28px;
  margin-top: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  /* orange */
  color: #ffffff;
  text-decoration: none;
  border: none;
  border-radius: 5px;
}

.courseNameHeding {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  /* identical to box height, or 20px */

  letter-spacing: -0.05em;

  color: #000000;
}
.courseNameDIscrpription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: rgba(113, 117, 121, 0.5);
}
