@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");

.sidebar {
  height: auto;
  width: 18vw;

  /* position: absolute; */
  /* left: 0; */
  background-color: white;
  position: relative;
}

a {
  text-decoration: none;
}
.sidebar-cnt-head {
  height: 6vh;
  display: grid;
  place-items: center;
  margin-top: 2vh;
  margin-bottom: 1vh;
}

.sidebar-cnt-head-text {
  text-align: left;
  width: 10vw;
  margin-left: -2vh;
  /* font-size: 16px; */
  font-size: 1.1vw;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
}

.sidebar-cnt-main-cnt {
  display: grid;
  grid-template-columns: 2vw 10vw;
  justify-content: center;
  align-items: center;
  height: 8vh;
}
.sidebar-cnt-main:hover {
  background-color: rgba(230, 252, 245, 1);
  color: #e6fcf5;
}

.sidebar-cnt-main-active {
  color: rgba(24, 135, 117, 1);
}

.sidebar-cnt-txt {
  text-align: left;
  padding-left: 1vw;
  font-size: 1.1vw;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
}
.sidebar-footer {
  height: 10vh;
  width: 100%;
  display: grid;
  place-items: center;
  position: absolute;
  bottom: 4vh;
}
.sidebar-footer button {
  width: 12vw;
  height: 5vh;
  background-color: #007848;
  border-radius: 20px;
  border: none;
  font-family: "Nunito", sans-serif;
  color: white;
  font-size: 0.7rem;
  margin: 14px;
}
.user{
  height: 8vh;
display: grid;
margin-bottom: -5vw;
    place-items: center;
    font-weight: 700;
    font-size: 1vw;
    color: white;
    margin-top: 5vw;
    position: absolute;
    right: 6vw;
    margin-top: 5vw;
    bottom: 0;
  }
  .user button {
    font-family: "Nunito", sans-serif;
    display: grid;
    grid-template-columns: 2vw 1fr;
    height: 5vh;
    width: 8vw;
    border: none;
    border-radius: 6px;
  }
  
  .user div {
    display: grid;
    place-items: center;
    height: 100%;
    font-weight: 700;
    font-size: 1vw;
    color: white;
  }
  