.admin-overdue-bottom-table-cnt-emp {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  padding: 1vw 2vw;
}
.admin-certificate-bottom-table-cnt-emp {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  padding: 1vw 2vw;
}
