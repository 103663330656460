.outer-details-container {
  /* border: 2px solid gray; */
  margin: 54px;
}

.bigger-container-header {
  color: #2c343e;
  font-weight: 500;
  font-family: "Poppins";
}

.user-data-bigger-container {
  font-family: "Poppins";
  margin-top: 16px;
  margin-bottom: 8px;
}

.sortcode-list-style {
  margin: 8px 15px;
  list-style: none;
  color: #2c343e;
  font-family: "Poppins";
}

.text-area-box {
  display: flex;
  justify-content: space-between;
  background-color: #e5e5e5;
}

.body-box-header {
  display: flex;
  /* border:2px solid red; */
}

.dummy-box {
  display: flex;
  justify-content: space-between;
  padding: 0px 18px;
  margin: 25px 0px;
}

.save-dummy-box {
  display: flex;
  justify-content: space-between;
  margin: 14px 12px;
}
/* .btn_addccs{
    width: 10%;
}
.ccs_{
    width: 60%;
} */

/* .form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
} */

/* .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0079bf;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
} */

/* .form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
} */
