.addItem-container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 1em 0 0 1em;
  padding: 10px;
}

.addItem-btn {
  font-family: "Nunito", sans-serif;

  background: #D55628;
  width: 8vw;
  min-height: 4vh;
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  color: white;
}
.addItem-heading {
  color: #00495f;
  height: 2em;
  font-weight: 600;
  font-size: 25px;
  display: grid;
  align-items: center;
  justify-content: left;
}

.addItem-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  align-items: center;
}

.addItem-inputLabel {
  width: 20vw;
}
.addItem-inputBar {
  width: 20vw;
  height: 4vh;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
}
.addItem-inputSelect {
  height: 4vh;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
}
.addItem-textarea {
  width: 20vw;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
}

.draggableItem-li {
  margin-top: 1em;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}
.draggableTopic-li {
  margin-top: 1em;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}
