@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

/* font-family: "Nunito", sans-serif; */
/* font-family: "Poppins", sans-serif; */

.admin-lesson-head {
  font-weight: 600;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
}

.admin-lesson {
  margin: 1vw;
}

.admin-lesson-body-top {
  margin: 0 0 0 0;
  background-color: #fa9600;
  border-radius: 6px;
  height: 6vh;
  display: grid;
  align-items: center;
}

.admin-lesson-body-top-cnt {
  display: flex;
}
.admin-lesson-body-top-cnt div {
  margin: 0 1vw;
  color: white;
  display: grid;
  place-items: center;
  font-family: "Nunito", sans-serif;
}

.admin-lesson-body-txt {
  margin: 1vw 2vw 2vw 2vw;
  color: #717579;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.admin-lesson-body-btn {
  background-color: #007c84;
  border-radius: 6px;
  height: 4vh;
  color: white;
  border: none;
  padding: 0 1vw;
  margin-left: 2vw;
}

.admin-lesson-body-item {
  margin-left: 2vw;
  border: 2px solid #d7d7d7;
  border-radius: 6px;
}

.admin-lesson-body-item-txt {
  margin: 1vw 0 0 2vw;
  color: #717579;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.admin-lesson-body-input {
  display: grid;
  place-items: center;
}

.admin-lesson-body-input input {
  border: 2px solid #d7d7d7;
  border-radius: 6px;
  height: 4vh;
  width: 15vw;
  margin-left: 2vw;
}
